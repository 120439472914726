import React from 'react';
import LogoIcon from '../../images/logo_master.png';


const Logo = ({link, title}) => {
    return (
        <a href={link} target={'_blank'}>
            <img src={LogoIcon} alt={title} />
        </a>
    )
}

export default Logo