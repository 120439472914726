import React from 'react';

import Form from '../Form';

const Order = () => {
    return (
        <div className="order" id="order">
            <div className="order-wrapper">
                <div className="order-form">
                    <Form link={'https://refinance.tascombank.ua/api/corpsite'} />
                </div>
                <div className="order-block">
                    <div className="order__title">
                        <h2>Замовити <span>просто</span> картку</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Order;