//Core
import React, {useEffect} from "react"

//Header

//Components
import Main from '../components/Main';
import About from '../components/About';
import Footer from '../components/Footer';
import Order from '../components/Order';
import FAQ from "../components/FAQ";
import SEO from '../components/Seo';

//Styles
import '../styles/index.scss';

const IndexPage = () => {

    useEffect(() => {
        const main = document.querySelector('.main');
        const windowHeight = window.innerHeight;
        // if(window.innerWidth >= 1024) {
            main.style.height = `${windowHeight}px`;
        // }
    });

    return (
        <div className="content">
            <SEO title="ПРОСТА КАРТКА" description="ПРОСТА КАРТКА" />
            <Main title={'Годі терпіти складні кредити'} />
            <About />
            <Order />
            <FAQ/>
            <Footer />
        </div>
    )
}

export default IndexPage
