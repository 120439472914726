import React from 'react';

import Logo from '../Logo'
import Card from '../../images/card.png';
import { getUrlParams } from '../../helpers/params';
import { getCookie, setCookie } from '../../helpers/cookie';

const Main = ({title}) => {

    const scrollToOrder = () => {
        document.getElementById('order').scrollIntoView({
            behavior: 'smooth'
        });
    }

    const {aff_sub, aff_id} = getUrlParams();
    const userCookieSub = getCookie('CLICK_ID');
    const userCookieId = getCookie('AID');
    
    if(userCookieSub) {
        if(userCookieSub === aff_sub) {
            setCookie('CLICK_ID', aff_sub, 30);
        }
        else {
            if(aff_sub) {
                setCookie('CLICK_ID', aff_sub, 30);
            }
        }
    }
    else {
        if(aff_sub) {
            setCookie('CLICK_ID', aff_sub, 30);
        }
    }

    if(userCookieId) {
        if(userCookieId === aff_id) {
            setCookie('AID', aff_id, 30);
        }
        else {
            if(aff_id) {
                setCookie('AID', aff_id, 30);
            }
        }
    }
    else {
        if(aff_id) {
            setCookie('AID', aff_id, 30);
        }
    }

    return (
        <div className="main">
            <div className="main__logo">
                <Logo link={'https://tascombank.ua/you/cards/prosto_kartka?utm_source=landing&utm_medium=perfomance&utm_campaign=prostocard'} title={'TAScombank logo'} />
            </div>
            <div className="main-wrapper">
                <div className="main__block">
                    <div className="main__title">
                        <h1>{title}</h1>
                    </div>
                    <div className="main__btn">
                        <button className="btn btn--red" onClick={() => scrollToOrder()} type="button">Замовити картку</button>
                    </div>
                </div>
                <div className="main__image">
                    <div className="main__image--card">
                        <img src={Card} alt={"Card ТАСКОМБАНК"} />
                    </div>
                </div>
            </div>
            {/*<div className="main__details">
                <a href="https://tascombank.ua/files/Ofitsiini_pravyla_Aktsii_%C2%ABU_Novyi_Rik_%E2%80%93_z_novym_IP....pdf" target='_blank'>*Правила акції</a>
            </div>*/}
        </div>
    )
}

export default Main;