import React, { useState } from "react";
import AccordionItem from "./AccordionItem";

// Styles
import "./style.scss"

const Accordion = ({ questionsAnswers }) => {
    const [activeIndex, setActiveIndex] = useState();

    const setActive = (index) => {
        if (activeIndex !== 'undefined' && activeIndex === index) {
            setActiveIndex(undefined);
        } else {
            setActiveIndex(index);
        }
    }

    const renderedQuestionsAnswers = questionsAnswers.map((item, index) => {
        const showDescription = index === activeIndex ? "show-description" : "";
        const fontWeightBold = index === activeIndex ? "open" : "";
        const ariaExpanded = index === activeIndex ? "true" : "false";
        return (
            <AccordionItem
                showDescription={showDescription}
                fontWeightBold={fontWeightBold}
                ariaExpanded={ariaExpanded}
                item={item}
                index={index}
                key={item.question}
                onClick={() => {
                    setActive(index);
                }}
            />
        );
    });

    return (
        <div className="faq-accordion">
            <dl className="faq-accordion__list">{renderedQuestionsAnswers}</dl>
        </div>
    );
};

export default Accordion;