import React from 'react';
import Icon1 from '../../images/icon-1.png';
import Icon2 from '../../images/icon-2.png';
import Icon3 from '../../images/icon-3.png';
import Icon4 from '../../images/icon-4.png';

const About = () => {
    return (
        <div className="about">
            <div className="about-wrapper">
                <div className="about__header">
                    <h2><span>ПРОСТО </span>КАРТКА – картка без ускладнень </h2>
                    <p>Плати лише за фактичне користування кредитом. Інші операції без обмежень.</p>
                </div>
                <div className="about__body">
                    <div className="about__body-item">
                        <div className="about__item-icon">
                            <img src={Icon2} alt={'icon'} />
                        </div>
                        <div className="about__item-text">
                            до <span className="large">100 000</span><span className="small"> грн</span> кредитного ліміту
                        </div>  
                    </div>
                    <div className="about__body-item">
                        <div className="about__item-icon">
                            <img src={Icon1} alt={'icon'} />
                        </div>
                        <div className="about__item-text">
                            <span className="large">0</span><span className="small"> грн</span> зняття кредитних коштів у банкоматах без обмежень
                        </div>  
                    </div>
                    <div className="about__body-item">
                        <div className="about__item-icon">
                            <img src={Icon1} alt={'icon'} />
                        </div>
                        <div className="about__item-text">
                            <span className="large">0</span><span className="small"> грн</span> за переказ кредитних коштів у межах України
                        </div>  
                    </div>
                    <div className="about__body-item">
                        <div className="about__item-icon">
                            <img src={Icon1} alt={'icon'} />
                        </div>
                        <div className="about__item-text">
                        <span className="large">0</span><span className="small"> грн</span> за відкриття та обслуговування
                        </div>  
                    </div>
                    <div className="about__body-item">
                        <div className="about__item-icon">
                            <img src={Icon3} alt={'icon'} />
                        </div>
                        <div className="about__item-text">
                        в <span className="large">TAS2U</span> - керуйте коштами онлайн
                        </div>  
                    </div>
                    {/*<div className="about__body-item">
                        <div className="about__item-icon">
                            <img src={Icon4} alt={'icon'} />
                        </div>
                        <div className="about__item-text">
                            <span className="large">0,15</span><span className="small"> %</span> у день
                        </div>  
                    </div>*/}
                </div>
            </div>
        </div>
    )
}

export default About;