import React from 'react';

const ButtonSubmit = ({title}) => {
    return (
        <div className="form-btn-wrapper">
            <button className="btn">{title}</button>
        </div>
    )
}

export default ButtonSubmit;