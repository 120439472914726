import React from 'react';
import Phone from '../../images/phone.png';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-wrapper">
                <div className="footer__left">
                    <p className="large">АТ «ТАСКОМБАНК»</p>
                    <p>Зареєстровано 21.10.1991 НБУ за №45.</p>
                    <p>Ліцензія НБУ №84 від 25.10.2011.</p>
                </div>
                {/* <div className="footer__center">
                    <a href="tel:8778">
                        <img src={Phone} alt="Phone" />
                        8778*
                    </a>
                    <p>* Дзвінки з мобільних безкоштовно.</p>
                </div> */}
                <div className="footer__right">
                    <a target={'_blank'} href="https://tascombank.ua/you/cards/prosto_kartka?utm_source=landing&utm_medium=perfomance&utm_campaign=prostocard">tascombank.ua</a>
                    <p>Залишаючи номер телефону, Ви підтверджуєте згоду на обробку Ваших персональних даних</p>
                </div>
            </div>
            <div className="footer-row">
                <p>Картка з кредитним лімітом. Максимальна сума кредиту – до 100 тис. грн, реальна річна процентна ставка – 72% річних, строк кредиту – 12 місяців, автопролонгація на такий самий строк. Детальна інформація на tascombank.ua або за номером телефону 0 800 503 580, цілодобово (дзвінки з номерів національних операторів на території України безкоштовно). Тільки для повнолітніх.</p>
            </div>
        </div>
    )
}

export default Footer;