import React from "react";

const AccordionItem = ({
                           showDescription,
                           ariaExpanded,
                           fontWeightBold,
                           item,
                           index,
                           onClick,
                       }) => (
        <div className="faq-accordion__question">
            <dt>
                <button
                    aria-expanded={ariaExpanded}
                    aria-controls={`faq-accordion${index + 1}_desc`}
                    data-qa="faq-accordion__question-button"
                    className={`faq-accordion__question-button ${fontWeightBold}`}
                    onClick={onClick}
                >
                    {item.question}
                </button>
            </dt>
            <dd>
                <div
                    id={`faq-accordion${index + 1}_desc`}
                    data-qa="faq-accordion__desc"
                    className={`faq-accordion__desc ${showDescription}`}
                >
                    {item.answer}
                </div>
            </dd>
        </div>
    );

export default AccordionItem;