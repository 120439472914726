import React from 'react';

import InputField from './InputField';
import ButtonSubmit from './ButtonSubmit';
import Checkbox from './Checkbox';

import { onSuccess, onFail, onSendSMS } from "../../UI/PopUp"

import useForm from "../../hooks";

const Form = (link, product) => {

    const {
        state,
        smsContext,
        changeName,
        changePhone,
        checkedName,
        checkedPhone,
        toggleRule,
        onClick,
      } = useForm(link, product)
    const { name, phone, fetch, success, fail, sendSMS, rule } = state

    return (
        <>
        <form id="form" onSubmit={e => {
            e.preventDefault()
            onClick()
          }}>    
            <InputField 
            title={"Ім'я"} 
            type={'text'} 
            placeholder={"Ім'я"} 
            message={'Перевірте правильність вводу імені або прізвища'}
            change={changeName}
            checked={name.checked}
            checkedFunc={checkedName}
            value={name.value}
            valid={name.valid}
            full={true}
            />
            <InputField 
            title={"Номер мобільного телефону"} 
            type={'phone'} 
            placeholder={'Номер телефону'} 
            message={'Перевірте правильність вводу номеру телефона'}  
            change={changePhone}
            checked={phone.checked}
            value={phone.value}
            checkedFunc={checkedPhone}
            valid={phone.valid}
            full={true}
            />
            <Checkbox onClick={toggleRule} checked={rule} />
            <ButtonSubmit click={onClick} title={'Замовити картку'} />
        </form>
        {sendSMS ? onSendSMS(phone.value, smsContext) : null}
        {success ? onSuccess() : null}
        {fail ? onFail() : null}
        </>
    )
}

export default Form