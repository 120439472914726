import React from "react";

import Accordion from "./../../components/Accordion/Accordion";

const questionsAnswers = [
    {
        question: "Калькулятор",
        answer: <p>Розрахувати кредит можна на головному сайті АТ "ТАСКОМБАНК" у підрозділі "Калькулятор" <a href="https://tascombank.ua/you/cards/prosto_kartka" target="_blank">за посиланням</a></p>,
    },
    {
        question: "Документи продукту",
        answer: <>
            <p><a href="https://tascombank.ua/files/Poperedzhennia_ta_mozhlyvi_naslidky_KK_202010323.pdf" target="_blank">Попередження та можливі наслідки користування послугою</a></p>
            <p><a href="https://tascombank.ua/files/Porivnialni_umovy_KK_090922.pdf" target="_blank">Порівняльні умови кредитних карток</a></p>
            <p><a href="https://tascombank.ua/files/Taryfy_Prosto_Kartka_23062022.pdf" target="_blank">Тарифи пакету послуг «Просто картка»</a></p>
            <p><a href="https://tascombank.ua/files/Rozrakhunok_sukupnoi_vartosti_Prosto_Kartka-1602.pdf" target="_blank">Розрахунок сукупної вартості</a></p>
            <p><a href="https://tascombank.ua/files/Istotni_kharakterystyky_poslugy_z_nadannia_spozhyvchogo_kredytu_za_produktom__kredytna_kartka_%C2%ABProsto_Kartka%C2%BB_090922.pdf" target="_blank">Істотні характеристики послуги з надання споживчого кредиту за продуктом кредитна картка «Просто Картка»</a></p>
            <p><a href="https://tascombank.ua/files/Pasport_Prosto_Kartka_090922.pdf" target="_blank">Паспорт споживчого кредиту Просто Картка</a></p>
            <p><a href="https://tascombank.ua/files/publichni_pravyla_vykorystannya_napered_oplachenykh_kartok_z_elektronnimi_hroshyma_020718.pdf" target="_blank">Публічні правила використання електронних грошей на базі наперед оплачених карток міжнародної платіжної системи MasterCard International</a></p>
            <p><a href="https://tascombank.ua/files/shema-konvertatsii-visa&mc.pdf" target="_blank">Схема конвертації операцій за платіжними картками MasterCard і Visa</a></p>
            <p><a href="https://tascombank.ua/files/_partnerski_programy_1.docx" target="_blank">Партнерські програми</a></p>
            <p><a href="https://tascombank.ua/files/Opys_zarakhuvannia_ta_chas_dostupnosti_koshtiv_na_potochnykh_rakhunkakh_2620.pdf" target="_blank">Опис зарахування та час доступності коштів на поточних рахунках 2620</a></p>
            <p><a href="https://tascombank.ua/files/Limity_na_vydatkovi_operatsiyi_za_platizhnymy_kartkamy_01062020.pdf" target="_blank">Ліміти на видаткові операції за платіжними картками</a></p>
            <p><a href="https://tascombank.ua/files/Poperedzhennia_ta_mozhlyvi_naslidky_KK.pdf" target="_blank">Попередження та можливі наслідки для клієнта в разі користування послугою "Кредитна карта"</a></p>
            <p><a href="https://tascombank.ua/files/Taryfy_na_poslugy_shcho_nadaiutsia_v_ramkakh_servisu_Internet-banking_TAS2U-01-02-2021.xlsx" target="_blank">Тарифи на послуги, що надаються в рамках сервісу «Інтернет-банкінг TAS2U»</a></p>
            <p><a href="https://tascombank.ua/files/Pamiatka_pozychalnyka_(spozhyvchyi_kredyt)_TAS.PDF" target="_blank">Пам'ятка позичальника (споживчий кредит)</a></p>
            <p><a href="https://tascombank.ua/files/Pamiatka_dlia_pozychalnyka_(skrutne_stanovyshche)_TAS.PDF" target="_blank">Пам'ятка для позичальника (скрутне становище)</a></p>
            <p><a href="https://tascombank.ua/files/Typova_forma_Zaiavy-dogovoru_pro_pryiednannia_do_DKBO_na_ukladannia_Dogovoru_pro_kompleksne_bankivske_29092021.pdf" target="_blank">Типова форма Заяви-договору про приєднання до ДКБО на укладання Договору про комплексне банківське обслуговування (зі зразками документів)</a></p>
            <p><a href="https://tascombank.ua/files/Sposoby_pogashennia_kredytu_AT_TASKOMBANK-3011.pdf" target="_blank">Способи погашення кредиту АТ «ТАСКОМБАНК»</a></p>
            <p><a href="https://tascombank.ua/files/Typova_forma_Publichnoi_propozytsii_AT_TASKOMBANK_na_ukladannia_DKBO_fizychnykh_osib_1711.pdf" target="_blank">Типова форма публічної пропозиції АТ «ТАСКОМБАНК» на укладання Договору про комплексне банківське обслуговування фізичних осіб Актуальна версія з 20.10.2021</a></p>
            <p><a href="https://tascombank.ua/files/Typova_forma_Zaiavy-dogovoru_pro_pryiednannia_do_Publichnoi_propozytsii__AT_%C2%ABTASKOMBANK%C2%BB_29102021.pdf" target="_blankk">Типова форма Заяви-договору про приєднання до Публічної пропозиції АТ «ТАСКОМБАНК»</a></p>
        </>,
    },
];

const FAQ = () => {
    return (
        <div className="faq">
            <div className="faq__header">
                <h2>ВІДПОВІДІ НА ВАШІ ЗАПИТАННЯ</h2>
            </div>
            <Accordion questionsAnswers={questionsAnswers} />
        </div>
    )
};

export default FAQ;
