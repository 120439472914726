import Swal from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"

export const smsErrors = {
  '451': 'Невiрний номер телефону',
  '452': 'Щось пішло не так, спробуйте пізніше',
  '453': 'Невiрний код',
  '454': (minutes) => `Помилка: Спробуйте через ${minutes} хвилин(и)`
};

export const onSendSMS = (phoneNumber, smsContext) => {
  const { startSendSms, sendSMSReq, reSendSmsReq, confirmSmsReq } = smsContext;
  const resendBtn = document.createElement("span")
  resendBtn.innerText = 'Вiдправити знов'
  resendBtn.className = 'resend'
  resendBtn.onclick = reSendSmsReq;

  Swal.queue([{
    text: 'Чи вiрний цей номер телефону?',
    title: phoneNumber,
    showCancelButton: true,
    cancelButtonText: 'Нi',
    confirmButtonText: 'Так',
    showLoaderOnConfirm: true,
    allowOutsideClick: false,
    preConfirm: sendSMSReq
  }]).then((result) => {
    if (result.value) {  
      const swalObj = {
        title: `Введiть код пiдтвердження`,
        input: 'number',
        confirmButtonText: 'Пiдтвердити',
        showCancelButton: true,
        cancelButtonText: 'Вiдмiнити',
        allowOutsideClick: false,
        html: resendBtn,
        showLoaderOnConfirm: true,
        preConfirm: confirmSmsReq
      };

      if (result.value[0]) {
        swalObj.footer = `Введіть номер з СМС, яке ви отримали або спробуйте ще раз через ${result.value[0]} хвилин(и)`;
      }

      Swal.fire(swalObj).then(() => startSendSms(false))
    } else {
      startSendSms(false)
    }
  })
}

export const onSuccess = (
  message = `Ваша заявка прийнята. Чекайте на дзвінок!`
) => {
  Swal.fire("Вітаємо!", message, "success")
}
export const onFail = () => {
  Swal.fire("Помилка!", `Сервіс не відповідає спробуйте пізніше`, "error")
}

export const onRule = () => {
  Swal.fire(
    "Увага!",
    `Для відправки заяки потрібно  дати згоду на обробку та використання своїх персональних даних`,
    "info"
  )
}
